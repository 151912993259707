<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        <p v-if="$route.params.id_role">LISTA DE {{ $store.state.menuName }}</p>
        <v-btn
          v-if="$route.params.id_role"
          color="primary"
          :to="`/ficheros/folderEntities/control/${$route.params.id_role}`"
          elevation="0"
          dark
          rounded
          class="mb-2 ml-5"
          :loading="!$store.state.dataList"
        >
          AGREGAR NUEVO
        </v-btn>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-container v-if="!$store.state.dataList" style="height: 400px">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Cargando informacíon
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        v-if="$store.state.dataList"
        :item-class="itemRowBackground"
        :headers="headers"
        :items="itemsEntities"
        :search="search"
        :custom-filter="filterOnlyCapsText"
      >
        <template v-slot:[`item.moredata`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                elevation="0"
                x-small
                v-bind="attrs"
                v-on="on"
                @click="openPhone(item.id)"
                color="blue-grey darken-3"
              >
                <v-icon dark> mdi-phone </v-icon>
              </v-btn>
            </template>
            <span>Teléfonos</span>
          </v-tooltip>

          <!--  <v-icon small color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template v-slot:[`item.moredata2`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                elevation="0"
                x-small
                v-bind="attrs"
                v-on="on"
                @click="openContacts(item.id)"
                color="blue-grey darken-3"
              >
                <v-icon dark> mdi-account-multiple </v-icon>
              </v-btn>
            </template>
            <span>Contactos</span>
          </v-tooltip>
          <!--  <v-icon small color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="$route.params.id_role"
            medium
            color="orange"
            class="mr-2"
            @click="editEntitie(item.id)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="!$route.params.id_role"
            medium
            color="primary"
            class="mr-2"
            @click="sendData(item.id)"
          >
            mdi-cursor-default-click
          </v-icon>
          <!--  <v-icon small color="red" @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </template>
      </v-data-table>
    </v-card>

    <v-footer app color="transparent" height="72" inset>
      <div>
        <div class="circle__legends colorRed"></div>
        Entidad sin documento
      </div>
    </v-footer>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "listEntitiesCom",
  data() {
    return {
      search: "",

      headers: [
        {
          text: "Documento",
          align: "start",
          value: "document",
        },
        { text: "Nombre Comercial", value: "business_name" },
        { text: "Ubigeo", value: "location" },
        { text: "Dirección", value: "address" },
        { text: "Notas", value: "notes" },
        //{ text: "Ult.Movimiento", value: "ultmoviment" },
        //{ text: "Pro.Promedio", value: "ppromedio" },
        { text: "Teléfonos", value: "moredata" },
        { text: "Contactos", value: "moredata2" },
        { text: "Acciones", value: "actions" },
      ],
      dataList: false,

      nameTitle: "",
      btnTitle: "",
    };
  },
  mounted() {
    this._getEntities(this.$route.params.id_role);
    this.validData();
    this.dataList = true;
  },
  computed: {
    ...mapState(["itemsEntities", "itemsMenu"]),
  },
  methods: {
    ...mapActions(["_getEntities", "_getPhone", "_getContacts"]),
    validData() {
      if (this.$route.params.id_role == 11) {
        this.nameTitle = "CLIENTES";
        this.btnTitle = "CLIENTE";
      } else if (this.$route.params.id_role == 15) {
        this.nameTitle = "PROVEEDORES";
        this.btnTitle = "PROVEEDOR";
      }
    },

    openPhone(id_entitie) {
      this.$store.state.id_entitie_selected = id_entitie;
      this._getPhone();
      this.$store.state.modalPhonesEdit = !this.$store.state.modalPhonesEdit;
    },

    openContacts(id_entitie) {
      this.$store.state.id_entitie_selected = id_entitie;
      this._getContacts();
      this.$store.state.modalContactsEdit = !this.$store.state
        .modalContactsEdit;
    },

    sendData(id) {
      this.$store.state.master_id_agente = id;
      this.$store.state.modalAgente = false;
    },

    editEntitie(id) {
      this.$router.push("/ficheros/folderEntities/control/edit/" + id);
    },

    itemRowBackground: function(item) {
      return item.document === "" || item.document === null
        ? "rowRed"
        : "white";
    },
    filterOnlyCapsText(value, search) {
      let text = search ? search.toLocaleUpperCase() : null;
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(text) !== -1
      );
    },
  },
};
</script>

<style lang="scss">
.rowRed {
  background: #ffd6d3;
  color: #540000;
}

.circle__legends {
  height: 20px;
  width: 20px;
  background: #ffd6d3;
  float: left;
  border-radius: 250px;
  margin-right: 10px;
}
</style>
